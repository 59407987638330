define("securitytrax-ember-ui/components/html/st-map/tech-today-map/component", ["exports", "securitytrax-ember-ui/components/html/st-map/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var TechTodayMap = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class TechTodayMap extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);
    }

    initMapMarkers() {
      try {
        var mapGroup = new H.map.Group();
        var lineString = new H.geo.LineString();
        var markers = [];
        this.map.addObject(mapGroup); //Add event markers

        for (var [index, event] of this.args.eventPoints.entries()) {
          var markerHtml = '<div class="tech-today-marker-container">' + '<div class="tech-today-event-marker ' + event.calendarEvent.grayClass + '" style="' + event.calendarEvent.get('calendarEventType.styleColor') + '">' + (index + 1) + '</div>' + '</div>';
          var markerIcon = new H.map.DomIcon(markerHtml);
          var markerPoint = this.getOffsetPoint(event.geoPoint.x, event.geoPoint.y, markers);
          var marker = new H.map.DomMarker(markerPoint, {
            icon: markerIcon
          });
          lineString.pushPoint(markerPoint);
          mapGroup.addObject(marker);
          markers.push(marker);
          marker.setZIndex(index);
        } //Add route polyline if we have multiple points


        if (lineString.getPointCount() > 1) {
          var polylineLine = new H.map.Polyline(lineString, {
            style: {
              strokeColor: '#0f86d3',
              lineWidth: 3,
              lineDash: [4, 4],
              lineCap: 'round',
              lineJoin: 'round'
            }
          });
          mapGroup.addObject(polylineLine);
        } //center screen on bounding box of all objects in the map


        this.map.getViewModel().setLookAtData({
          bounds: mapGroup.getBoundingBox()
        }); //Queue up a function that zooms out a bit

        setTimeout(() => {
          var currentZoom = this.map.getZoom();
          this.map.setZoom(Math.min(currentZoom - 0.5, 19));
        }, 50);
      } catch (err) {
        throw err;
      }
    }

    initMapAndMarkers() {
      this.initMap();
      this.initMapMarkers();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initMapMarkers", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "initMapMarkers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initMapAndMarkers", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "initMapAndMarkers"), _class.prototype)), _class));
  _exports.default = TechTodayMap;
});