define("securitytrax-ember-ui/components/html/st-map/tech-today-map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hi3kHtuy",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"st-map tech-today-map\"],[24,1,\"map\"],[4,[38,1],[[30,[36,0],[[32,0],\"initMapAndMarkers\"],null]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-map/tech-today-map/template.hbs"
    }
  });

  _exports.default = _default;
});