define("securitytrax-ember-ui/components/customer-search-list/component", ["exports", "moment", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/classes/misc-input-list", "securitytrax-ember-ui/classes/user-defined-fields"], function (_exports, _moment, _objHelpers, _emberConcurrency, _tableColumnSort, _miscInputList, _userDefinedFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component.extend(_tableColumnSort.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    userService: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    grants: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    screen: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    customers: undefined,
    customerIsCanceled: undefined,
    entity: undefined,
    installDateFrom: undefined,
    installDateTo: undefined,
    installStatus: undefined,
    officeLocations: undefined,
    saleDateFrom: undefined,
    saleDateTo: undefined,
    salesReps: undefined,
    installTechs: undefined,
    tagFilter: undefined,
    monitoringCompanies: undefined,
    customerIsLinked: undefined,
    interactiveServicesProviders: undefined,
    customerIsLinkedToIsp: undefined,
    miscFilters: undefined,
    miscUdfFilters: undefined,
    leadCompany: undefined,
    leadCompanyCampaigns: undefined,
    accountClasses: undefined,
    accountClassOptions: undefined,
    dynamicUdfOperators: undefined,
    tagName: '',
    limit: 50,
    page: 1,
    customerListCount: 0,
    appliedFiltersCount: 0,

    init() {
      this._super(...arguments);

      var intl = this.intl;
      this.UdfClass = new _userDefinedFields.default({
        store: this.store,
        model: null,
        intl: this.intl
      });
      (0, _objHelpers.initializeState)(this, {
        // stSortCurrentSortColumn: 'id', //init sort by id
        // stSortAscending: false, //init sort desc
        entity: 'customer',
        miscFilters: new _miscInputList.default(),
        miscUdfFilters: new _miscInputList.default({
          store: this.store,
          intl: this.intl
        }),
        dynamicUdfOperators: undefined,
        tagFilter: this.tagsService.tagFilter(),
        stSortableColumnOptions: [{
          name: intl.t('common.columns.id') + ' ' + intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: intl.t('common.columns.id') + ' ' + intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }, {
          name: intl.t('common.columns.name') + ' ' + intl.t('common.sorting.ascending'),
          value: 'lname',
          chevronDirection: 'up'
        }, {
          name: intl.t('common.columns.name') + ' ' + intl.t('common.sorting.descending'),
          value: '-lname',
          chevronDirection: 'down'
        }, {
          name: intl.t('common.columns.sale_date') + ' ' + intl.t('common.sorting.ascending'),
          value: 'sale_date',
          chevronDirection: 'up'
        }, {
          name: intl.t('common.columns.sale_date') + ' ' + intl.t('common.sorting.descending'),
          value: '-sale_date',
          chevronDirection: 'down'
        }, {
          name: intl.t('common.columns.address') + ' ' + intl.t('common.sorting.ascending'),
          value: 'city',
          chevronDirection: 'up'
        }, {
          name: intl.t('common.columns.address') + ' ' + intl.t('common.sorting.descending'),
          value: '-city',
          chevronDirection: 'down'
        }, {
          name: intl.t('common.columns.phone') + ' ' + intl.t('common.sorting.ascending'),
          value: 'primary_phone',
          chevronDirection: 'up'
        }, {
          name: intl.t('common.columns.phone') + ' ' + intl.t('common.sorting.descending'),
          value: '-primary_phone',
          chevronDirection: 'down'
        }]
      });
      this.setupEnabledFeaturesTask.perform();
      this.setupFiltersTask.perform();
    },

    miscFilterOperands: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.fname'),
        value: 'fname'
      }, {
        option: intl.t('common.filters.lname'),
        value: 'lname'
      }, {
        option: intl.t('common.columns.account_num'),
        value: 'customer_system_information.account_num'
      }, {
        option: intl.t('common.columns.email'),
        value: 'email'
      }, // { option: intl.t('common.filters.ssn'), value: 'customer_ssn.ssn' }, // BE doesn't allow filter on sensitive fields
      {
        option: intl.t('common.columns.phone'),
        value: 'phone'
      }, {
        option: intl.t('common.address.city'),
        value: 'city'
      }, {
        option: intl.t('common.address.state'),
        value: 'state'
      }, {
        option: intl.t('common.columns.address'),
        value: 'address1'
      }, {
        option: intl.t('common.address.zip'),
        value: 'zip'
      }, {
        option: intl.t('common.columns.id'),
        value: 'id'
      }, {
        option: intl.t('common.filters.business_name'),
        value: 'business_name'
      }, {
        option: intl.t('common.filters.business_contact'),
        value: 'business_contact'
      }, {
        option: intl.t('common.actions.searchOptions.properties.ispId'),
        value: 'customer_system_information.interactive_services_id'
      }, {
        option: intl.t('common.actions.searchOptions.properties.centralStationId'),
        value: 'customer_system_information.central_station_id'
      }];
    }),
    sourceOptionsTask: (0, _emberConcurrency.task)(function* () {
      return this.grants.getIndividualGrant('lead_companies.view') ? yield this.store.findAll('leadCompany') : [];
    }),
    leadCompanyCampaignsTask: (0, _emberConcurrency.task)(function* () {
      if (this.grants.getIndividualGrant('lead_company_campaigns.view') && this.leadCompany && this.leadCompany.get('id') > 0) {
        var leadCompany = yield this.store.findRecord('leadCompany', this.leadCompany.get('id'));
        return yield leadCompany.get('leadCompanyCampaigns');
      }

      return [];
    }),
    trueFalseOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: true,
        name: intl.t('common.confirm.true')
      }, {
        value: false,
        name: intl.t('common.confirm.false')
      }];
    }),
    locationsSorted: Ember.computed('locations', function () {
      return this.locations.sortBy('name');
    }),
    salesRepsSortedTask: (0, _emberConcurrency.task)(function* () {
      var reps = yield this.userService.getUsersList('sales_rep', 'customer');
      var ret = [];
      reps.forEach(rep => {
        ret.push(Ember.Object.create(rep));
      });
      return ret;
    }),
    getTechOptions: (0, _emberConcurrency.task)(function* () {
      return yield this.userService.getUsersList('tech');
    }),
    monitoringCompaniesSortedTask: (0, _emberConcurrency.task)(function* () {
      var monitoringCompanies = yield this.store.findAll('monitoringCompany');
      return monitoringCompanies.sortBy('name');
    }),
    interactiveServicesProvidersSortedTask: (0, _emberConcurrency.task)(function* () {
      var interactiveServicesProviders = yield this.store.findAll('interactiveServicesProvider');
      return interactiveServicesProviders.sortBy('name');
    }),
    installStatusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        id: 1,
        name: intl.t('common.status.installed')
      }, {
        id: 2,
        name: intl.t('common.status.notComplete')
      }, // {id: 3, name: intl.t('common.status.notScheduled')},
      {
        id: 4,
        name: intl.t('common.status.notInstalled')
      }];
    }),
    setupEnabledFeaturesTask: (0, _emberConcurrency.task)(function* () {
      try {
        var companyPublicInfo = yield this.sessionData.getPublicCompanyInfo();

        if (companyPublicInfo.ui_feature_flags && companyPublicInfo.ui_feature_flags.business_contact_column) {
          Ember.set(this, 'businessContactColumnEnabled', companyPublicInfo.ui_feature_flags.business_contact_column.enabled);
        }
      } catch (err) {
        ;
      }
    }),
    setupFiltersTask: (0, _emberConcurrency.task)(function* () {
      try {
        var hashData = yield (0, _emberConcurrency.hash)({
          miscUdfFilterOperands: this.UdfClass.miscUdfFilterOperands.perform(),
          dynamicUdfOperators: this.UdfClass.getAllAdminUdfObj.perform(),
          tagsOptions: this.tagsService.tagsOptionsTask.perform('customer'),
          leadCompanyCampaignOptions: this.leadCompanyCampaignsTask.perform(),
          sourceOptions: this.sourceOptionsTask.perform(),
          salesRepsSorted: this.salesRepsSortedTask.perform(),
          monitoringCompaniesSorted: this.monitoringCompaniesSortedTask.perform(),
          interactiveServicesProvidersSorted: this.interactiveServicesProvidersSortedTask.perform(),
          techOptions: this.getTechOptions.perform(),
          accountClassOptions: this.store.findAll('accountClass')
        });
        Ember.setProperties(this, hashData);
        Ember.set(this, 'miscFilterOperators', this.miscUdfFilters.miscFilterOperators());
        Ember.set(this, 'checkboxOptions', this.miscUdfFilters.checkboxOptions());

        if (this.miscUdfFilters.miscInputs && this.miscUdfFilters.miscInputs.length > 0) {
          this.miscUdfFilters.miscInputs.forEach(udfFilter => {
            udfFilter.fromDate = udfFilter.fromDate ? (0, _moment.default)(udfFilter.fromDate, 'MM/DD/YYYY') : null;
            udfFilter.toDate = udfFilter.toDate ? (0, _moment.default)(udfFilter.toDate, 'MM/DD/YYYY') : null;
          });
        }

        try {
          this.miscFilters.addMiscInput();
        } catch (err) {
          this.set('miscFilters', new _miscInputList.default());
          this.miscFilters.addMiscInput();
        }

        try {
          this.miscUdfFilters.addMiscInput();
        } catch (err) {
          this.set('miscUdfFilters', new _miscInputList.default());
          this.miscUdfFilters.addMiscInput();
        }
      } catch (error) {
        (false && Ember.warn(error, {
          id: 'app.component.customer-search-list.initFilterData'
        }));
      }
    }),
    filterCustomers: Ember.computed(function () {
      return this.filterCustomersTask.perform();
    }),
    filterCustomersTask: (0, _emberConcurrency.task)(function* () {
      try {
        var defaultFilter = [];

        if (this.saleDateFrom == undefined && this.saleDateTo == undefined) {
          defaultFilter = ['record_type = \'customer\''];
        }

        var newFilters = yield this.stFiltersService.getFiltersByGroupKey('allCustomersList', defaultFilter);
        var queryParams = {
          filter: newFilters,
          sort: this.stSortString ? this.stSortString : '-id',
          include: 'customer_order_information.rep_user,customer_system_information.install_calendar_event.calendar_user,location',
          limit: this.limit,
          page: this.page
        };

        if (this.page == 1) {
          yield this.getCount.perform(newFilters);
        }

        var customers = yield this.store.query('customer', queryParams);
        return customers;
      } catch (error) {
        (false && Ember.warn(error, {
          id: 'app.component.customer-search-list.filterCustomersTask'
        }));
      }
    }).restartable().maxConcurrency(1),
    getCount: (0, _emberConcurrency.task)(function* (newFilters) {
      var newFiltersString = newFilters.join(' and ');

      try {
        var returnDataNew = yield this.stAjax.request(this.stAjax.getUrl('/aggregate/customers?filter=' + newFiltersString), {
          method: 'GET'
        });
        this.set('customerListCount', returnDataNew.data[0].attributes.calculated);
      } catch (err) {//Do Nothing
      }
    }),
    updateLeadCompanyCampaignOptionsTask: (0, _emberConcurrency.task)(function* (data) {
      if (data) {
        Ember.set(this, 'leadCompany', data);
      }

      Ember.set(this, 'leadCompanyCampaigns', null);
      Ember.set(this, 'leadCompanyCampaignOptions', yield this.leadCompanyCampaignsTask.perform());
    }),
    initLeadCompanyCampaignOptionsTask: (0, _emberConcurrency.task)(function* (data) {
      if (data) {
        Ember.set(this, 'leadCompany', data);
      }

      Ember.set(this, 'leadCompanyCampaignOptions', yield this.leadCompanyCampaignsTask.perform());
    }),
    actions: {
      setStar(customer) {
        if (this.grants.getIndividualGrant('customers.modify')) {
          var currentStar = customer.get('isStarred');
          customer.set('isStarred', !currentStar);
          customer.save();
        }
      },

      changeRoute({
        id
      }) {
        this.router.transitionTo('customers.view', id);
      },

      filterCustomers() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'filterCustomers');
      },

      clearFilter() {
        Ember.setProperties(this, {
          customerIsCanceled: undefined,
          installDateFrom: undefined,
          installDateTo: undefined,
          installStatus: undefined,
          officeLocations: undefined,
          saleDateFrom: undefined,
          saleDateTo: undefined,
          salesReps: undefined,
          installTechs: undefined,
          monitoringCompanies: undefined,
          customerIsLinked: undefined,
          interactiveServicesProviders: undefined,
          customerIsLinkedToIsp: undefined,
          leadCompany: undefined,
          leadCompanyCampaigns: undefined,
          isStarred: undefined,
          accountClasses: undefined,
          tagFilter: this.tagsService.tagFilter(),
          'miscFilters.miscInputs': [{
            operand: undefined,
            operator: undefined,
            value: undefined
          }],
          'miscUdfFilters.miscInputs': [{
            operand: undefined,
            operator: undefined,
            value: undefined,
            fromDate: undefined,
            toDate: undefined
          }]
        });
        Ember.notifyPropertyChange(this, 'filterCustomers');
      },

      // action sent to pagination component
      getCustomers() {
        Ember.notifyPropertyChange(this, 'filterCustomers');
      },

      // action sent to sortable-column component
      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending, 'customersLoaded');
        Ember.notifyPropertyChange(this, 'filterCustomers');
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value, 'customersLoaded');
        Ember.notifyPropertyChange(this, 'filterCustomers');
      }

    }
  });
  var _default = Component;
  _exports.default = _default;
});