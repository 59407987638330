define("securitytrax-ember-ui/components/entity-search-list/component", ["exports", "lodash", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/classes/misc-input-list"], function (_exports, _lodash, _objHelpers, _emberConcurrency, _tableColumnSort, _miscInputList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component.extend(_tableColumnSort.default, {
    entityListService: Ember.inject.service('events/entity-list'),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    store: Ember.inject.service(),
    userService: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    leadStatusEvents: Ember.inject.service('events/lead-status-events'),
    leadStatuses: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    screen: Ember.inject.service(),
    filterGroupKeyStr: 'entitySearchList',
    changeStatusModalOpen: undefined,
    entity: undefined,
    entityRecords: undefined,
    leadStatus: undefined,
    officeLocations: undefined,
    source: undefined,
    sourceCampaign: undefined,
    miscFilters: undefined,
    assignedTo: undefined,
    tagFilter: undefined,
    tagName: '',
    limit: 50,
    page: 1,
    entityListCount: 0,
    queryParamsCount: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        changeStatusModalOpen: false,
        entity: 'lead',
        //customer?
        recordsLoaded: false,
        isAdtCompany: false,
        miscFilters: new _miscInputList.default(),
        tagFilter: this.tagsService.tagFilter(),
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'created',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-created',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.name') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'lname',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.name') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-lname',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('pages.leads.callback_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'lead_information.lead_callback_date',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('pages.leads.callback_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-lead_information.lead_callback_date',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.address') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'city',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.address') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-city',
          chevronDirection: 'down'
        } // relation.relation.attribute is not supported by BE right now for sorting
        // { name: this.intl.t('pages.leads.source') + ' ' + this.intl.t('common.sorting.ascending'), value: 'lead_source_information.lead_source.name', chevronDirection: 'up' },
        // { name: this.intl.t('pages.leads.source') + ' ' + this.intl.t('common.sorting.descending'), value: '-lead_source_information.lead_source.name', chevronDirection: 'down' },
        ]
      });

      try {
        this.miscFilters.addMiscInput();
      } catch (err) {
        this.set('miscFilters', new _miscInputList.default());
        this.miscFilters.addMiscInput();
      }

      this.setupEnabledFeaturesTask.perform();
      this.initDataTask.perform();
      this.leadStatusEvents.on('redoModels', this, '_eventCallback');
    },

    _eventCallback() {
      Ember.set(this, 'bulkIsChecked', false);
      this.bulkClickTask.perform(true);
    },

    willDestroy() {
      this._super(...arguments);

      this.leadStatusEvents.off('redoModels', this, '_eventCallback');
    },

    initDataTask: (0, _emberConcurrency.task)(function* () {
      var hashData = yield (0, _emberConcurrency.hash)({
        salesReps: this.salesRepsTask.perform(),
        leadStatusOptions: this.leadStatusOptionsTask.perform(),
        sourceOptions: this.sourceOptionsTask.perform(),
        tagsOptions: this.tagsService.tagsOptionsTask.perform('customer'),
        sourceCampaignOptions: this.sourceCampaignOptionsTask.perform()
      });
      Ember.setProperties(this, hashData);
    }),
    setupEnabledFeaturesTask: (0, _emberConcurrency.task)(function* () {
      try {
        var companyPublicInfo = yield this.sessionData.getPublicCompanyInfo();

        if (companyPublicInfo.ui_feature_flags && companyPublicInfo.ui_feature_flags.business_contact_column) {
          Ember.set(this, 'businessContactColumnEnabled', companyPublicInfo.ui_feature_flags.business_contact_column.enabled);
        }
      } catch (err) {
        ;
      }
    }),
    leadData: Ember.computed(function () {
      return this.leadDataTask.perform();
    }),
    leadDataTask: (0, _emberConcurrency.task)(function* () {
      var leadIncludes = [];

      if (this.grants.getIndividualGrant('lead_information.view')) {
        leadIncludes.push('lead_information.lead_type');
        leadIncludes.push('lead_information.lead_rep_user');
      } // If used for more than one entity type, right just lead, then we'll have to check entity type first because customer uses `customer_lead_source_information.view`


      if (this.grants.getIndividualGrant('lead_source_information.view') && this.grants.getIndividualGrant('lead_companies.view')) {
        leadIncludes.push('lead_source_information.lead_company');
      }

      if (this.grants.getIndividualGrant('lead_source_information.view') && this.grants.getIndividualGrant('lead_company_campaigns.view')) {
        leadIncludes.push('lead_source_information.lead_company_campaign');
      }

      var newFilters = yield this.stFiltersService.getFiltersByGroupKey(this.filterGroupKeyStr, ['record_type = \'lead\'']);
      var queryParams = {
        filter: newFilters,
        sort: this.stSortString ? this.stSortString : '-id',
        include: this.entity === 'lead' ? leadIncludes.join(',') : 'customer_order_information.rep_user, customer_system_information.install_calendar_event.user, location',
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        this.getCount.perform(newFilters);
      }

      var leadEntries = yield this.store.query('customer', queryParams);
      this.set('entityRecords', leadEntries);
      return {
        leadEntries,
        record_count: _lodash.default.get(leadEntries, 'meta.record_count', 0)
      };
    }),
    getCount: (0, _emberConcurrency.task)(function* (filter) {
      try {
        var returnDataNew = yield this.stAjax.request(this.stAjax.getUrl('/aggregate/customers?filter=' + filter.join(' and ')), {
          method: 'GET'
        });
        this.set('entityListCount', returnDataNew.data[0].attributes.calculated);
      } catch (err) {//Do Nothing
      }
    }),
    salesRepsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.userService.getUsersList('sales_rep', this.entity);
    }),
    leadStatusOptionsTask: (0, _emberConcurrency.task)(function* () {
      try {
        var blacklistedIds = ['1', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'];
        return yield this.leadStatuses.getLeadStatusOptionsTask.perform(blacklistedIds);
      } catch (e) {// console.log('this is an error!', e);
      }
    }),
    sourceOptionsTask: (0, _emberConcurrency.task)(function* () {
      return this.grants.getIndividualGrant('lead_companies.view') ? yield this.store.findAll('leadCompany') : [];
    }),
    sourceCampaignOptionsTask: (0, _emberConcurrency.task)(function* () {
      if (this.grants.getIndividualGrant('lead_company_campaigns.view') && this.source && this.source.get('id') > 0) {
        var leadCompany = yield this.store.findRecord('leadCompany', this.source.get('id'));
        return yield leadCompany.get('leadCompanyCampaigns');
      }

      return [];
    }),
    miscFilterOperands: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.fname'),
        value: 'fname'
      }, {
        option: intl.t('common.filters.lname'),
        value: 'lname'
      }, {
        option: intl.t('common.columns.account_num'),
        value: 'customer_system_information.account_num'
      }, {
        option: intl.t('common.columns.email'),
        value: 'email'
      }, //{ option: intl.t('common.filters.ssn'), value: 'customer_ssn.ssn' }, // BE doesn't allow filter on sensitive fields
      {
        option: intl.t('common.columns.phone'),
        value: 'primary_phone'
      }, {
        option: intl.t('common.address.city'),
        value: 'city'
      }, {
        option: intl.t('common.address.state'),
        value: 'state'
      }, {
        option: intl.t('common.columns.address'),
        value: 'address1'
      }, {
        option: intl.t('common.address.zip'),
        value: 'zip'
      }, {
        option: intl.t('common.columns.id'),
        value: 'id'
      }, {
        option: intl.t('common.filters.business_contact'),
        value: 'business_contact'
      }, {
        option: intl.t('common.filters.callcenter_rep'),
        value: 'lead_information.lead_callcenter_rep'
      }];
    }),
    miscFilterOperators: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.equals'),
        value: '='
      }, {
        option: intl.t('common.filters.starts_with'),
        value: '~%'
      }, {
        option: intl.t('common.filters.contains'),
        value: '%~%'
      }];
    }),
    locationsSorted: Ember.computed('locations', function () {
      return this.locations.sortBy('name');
    }),
    bulkChangeStatusFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        changeStatus: Ember.Object.create({
          stepNameKey: 'changeStatus',
          title: this.intl.t('pages.leads.change_lead_status_bulk'),
          stepComponentPath: 'multi-step-form/forms/leads/change-status'
        })
      });
    }),
    bulkFormOriginalModels: Ember.computed('grants.userGrants', function () {
      var grants = this.grants;
      return Ember.Object.create({
        customer: {
          model: null,
          copyRelationMap: {
            leadInformation: {
              doCopy: grants.getIndividualGrant('lead_information.view'),
              doSave: grants.getIndividualGrant('lead_information.modify'),
              relationships: {
                leadRepUser: {
                  doCopy: true,
                  doValidate: false,
                  doSave: false
                },
                leadStatus: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    selectedLeads: Ember.computed('entityRecords.{[],@each}.isChecked', function () {
      return this.getSelectedLeadsTask.perform();
    }),
    getSelectedLeadsTask: (0, _emberConcurrency.task)(function* () {
      var entityRecords = yield this.entityRecords;
      return entityRecords ? entityRecords.filter(record => {
        return record.isChecked;
      }) : [];
    }),
    bulkClickTask: (0, _emberConcurrency.task)(function* (checked) {
      var entityRecords = yield this.entityRecords;
      entityRecords.forEach(record => {
        Ember.set(record, 'isChecked', !checked);
      });
    }),
    setStarTask: (0, _emberConcurrency.task)(function* (row) {
      var currentStar;

      if (this.entity == 'lead') {
        if (this.grants.getIndividualGrant('lead_information.modify')) {
          var leadInformation = yield row.get('leadInformation');
          currentStar = leadInformation.get('leadIsStarred');
          Ember.set(leadInformation, 'leadIsStarred', !currentStar);
          yield leadInformation.save();
        }
      } else {
        if (this.grants.getIndividualGrant('customers.modify')) {
          currentStar = row.get('isStarred');
          row.set('isStarred', !currentStar);
          yield row.save();
        }
      }
    }),
    updateLeadCompanyCampaignOptionsTask: (0, _emberConcurrency.task)(function* (data) {
      if (data) {
        Ember.set(this, 'sourceCampaign', data);
      }

      Ember.set(this, 'sourceCampaign', null);
      Ember.set(this, 'sourceCampaignOptions', yield this.sourceCampaignOptionsTask.perform());
    }),
    initLeadCompanyCampaignOptionsTask: (0, _emberConcurrency.task)(function* (data) {
      if (data) {
        Ember.set(this, 'sourceCampaign', data);
      }

      Ember.set(this, 'sourceCampaignOptions', yield this.sourceCampaignOptionsTask.perform());
    }),
    actions: {
      changeRoute({
        id
      }) {
        var entity = this.entity;
        var infoRoute = entity === 'lead' ? 'leads.dashboard' : 'customers.view';
        this.router.transitionTo(infoRoute, id);
      },

      filterEntities() {
        this.set('page', 1);
        this.set('queryParamsCount', 1);
        Ember.notifyPropertyChange(this, 'leadData');
      },

      clearFilter() {
        Ember.setProperties(this, {
          assignedTo: undefined,
          status: undefined,
          sourceCampaign: undefined,
          source: undefined,
          dateCreatedFrom: undefined,
          dateCreatedTo: undefined,
          callbackDateFrom: undefined,
          callbackDateTo: undefined,
          leadStatus: undefined,
          tagFilter: this.tagsService.tagFilter(),
          'miscFilters.miscInputs': [{
            operand: undefined,
            operator: undefined,
            value: undefined
          }]
        });
        Ember.notifyPropertyChange(this, 'leadData');
      },

      //action sent to pagination component
      getRecords() {
        Ember.notifyPropertyChange(this, 'leadData');
      },

      //action sent to sortable-column component
      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending);
        Ember.notifyPropertyChange(this, 'leadData');
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value);
        Ember.notifyPropertyChange(this, 'leadData');
      }

    }
  });
  var _default = Component;
  _exports.default = _default;
});