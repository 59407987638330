define("securitytrax-ember-ui/components/customer/account-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b1zbiPHR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\" grid-x grid-padding-x grid-padding-y\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,6],[\"cell small-12 \",[30,[36,5],[[30,[36,4],[[35,0,[\"latitude\"]],[35,0,[\"longitude\"]]],null],\"large-8\",\"auto\"],null],\" quick-customer-info-container\"],null]],[12],[2,\"\\n        \"],[1,[30,[36,8],null,[[\"customer\",\"appointment\"],[[35,0],[35,7]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,5],[[35,9,[\"isDesktop\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[35,0,[\"latitude\"]],[35,0,[\"longitude\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"cell shrink small-12 large-4 map-container\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"customer\"],[[35,0]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"customer\",\"html/st-map/customer-dashboard-map\",\"isTestEnvironment\",\"unless\",\"and\",\"if\",\"concat\",\"appointment\",\"customer/quick-customer-info/quick-customer-info-content\",\"screen\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/account-status/template.hbs"
    }
  });

  _exports.default = _default;
});