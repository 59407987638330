define("securitytrax-ember-ui/components/html/st-map/controls/toggle-markers-control", ["exports", "@here/maps-api-for-javascript"], function (_exports, _mapsApiForJavascript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  //Custom control for HERE Maps that lets you toggle markers.
  var ToggleMarkersControl = (_dec = Ember._action, (_class = class ToggleMarkersControl extends _mapsApiForJavascript.default.ui.Control {
    constructor(markers, title = 'Markers', svgIcon = '/assets/icons.svg#icn-location') {
      super();
      this.markers = markers; //An array of markers

      /** 
       * Note markers can have an object set with setData() that has the following attributes
       * Optional: 'name' attribute to configure the name of the marker after the checkbox
       * Optional: 'childGroup' attribute can be set to toggle objects in the group along with the marker
       * Optional: 'color' attribute can be set to add a color splotch before the the marker checkbox
      **/

      this.title = title; //The title of the control

      this.svgIcon = svgIcon; //The path to the svg icon default to '/assets/icons.svg#icn-location'
    }

    renderInternal(el, doc) {
      //Create a button and overlay using mostly HERE Maps UI classes
      el.innerHTML = '<div id="toggle-markers-button" class="H_btn H_el">' + '<svg xmlns="http://www.w3.org/2000/svg" class="H_icon">' + '<use href="' + this.svgIcon + '" xlink:href="' + this.svgIcon + '"></use>' + '</svg>' + '</div>' +
      /**Control is just used in 1 place so it's hardcoded to only look good in the top left corner. If we need to use it elsewhere
      we can change H_top/H_left/style dynamically based on this.getAlignment()**/
      '<div id="toggle-markers-overlay" class="H_overlay H_el H_top H_left scrollable-overlay" style="top: 0px; margin: 0px 12px; left: 40px;">' + '<div class="H_rdo H_el">' + '<div class="H_rdo_title H_el">' + this.title + '</div>' + '<div id="toggle-markers" class="H_rdo_buttons H_el scrollable-overlay-list"></div>' + '</div>' + '</div>';
      super.renderInternal(el, doc); //Add an event listener to control the overlay

      var btnElement = document.getElementById('toggle-markers-button');
      var overlayElement = document.getElementById('toggle-markers-overlay');

      var onClick = event => {
        if (btnElement.contains(event.target)) {
          btnElement.classList.toggle('H_active');
          overlayElement.classList.toggle('H_open');
        } else if (overlayElement.contains(event.target)) {//Do nothing
        } else {
          btnElement.classList.remove('H_active');
          overlayElement.classList.remove('H_open');
        }
      };

      document.addEventListener('click', onClick);
      this.addMarkers(this.markers);
    }

    addMarkers(markers) {
      var markersElement = document.getElementById('toggle-markers');
      var baseCheckboxId = 'toggle-marker-checkbox-'; //Add a checkbox to the control for each marker using color and name found in getData()

      var _loop = function (index, marker) {
        var _ref;

        var checkboxId = baseCheckboxId + index;
        var markerItem = document.createElement('div');
        markerItem.className = 'toggle-marker-item';
        markerItem.innerHTML = (_ref = (marker.getData().color ? '<div class="toggle-marker-color-splotch" style="background-color: ' + marker.getData().color + '"></div>' : '') + '<input type="checkbox" id="' + checkboxId + '" class="toggle-marker-checkbox" checked>' + '<label for="' + checkboxId + '" class="toggle-marker-label">' + marker.getData().name) !== null && _ref !== void 0 ? _ref : '' + '</label>';
        markersElement.appendChild(markerItem); //Add event listeners to toggle marker visibility

        var onChange = event => {
          if (marker.getData().childGroup) {
            marker.getData().childGroup.setVisibility(event.target.checked);
          }

          marker.setVisibility(event.target.checked);
        };

        var checkbox = document.getElementById(checkboxId);
        checkbox.addEventListener('change', onChange);
      };

      for (var [index, marker] of markers.entries()) {
        _loop(index, marker);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "addMarkers", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "addMarkers"), _class.prototype)), _class));
  _exports.default = ToggleMarkersControl;
});