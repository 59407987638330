define("securitytrax-ember-ui/components/html/st-map/component", ["exports", "@glimmer/component", "@here/maps-api-for-javascript", "securitytrax-ember-ui/config/environment"], function (_exports, _component, _mapsApiForJavascript, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var STMap = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = (_temp = class STMap extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "map", _descriptor2, this);

      _initializerDefineProperty(this, "ui", _descriptor3, this);

      _initializerDefineProperty(this, "layers", _descriptor4, this);

      _initializerDefineProperty(this, "markers", _descriptor5, this);
    }

    initMap() {
      try {
        var _this$sessionData$get;

        var platform = new _mapsApiForJavascript.default.service.Platform({
          'apiKey': _environment.default['securitytrax-ember-ui']['here-map']['api_key']
        }); //Start with a default instead of making layers from scratch

        this.layers = platform.createDefaultLayers(); //remove unwanted map layers

        if (!this.args.satelliteView) {
          delete this.layers.raster.satellite;
        }

        if (!this.args.trafficView) {
          delete this.layers.vector.normal.traffic;
          delete this.layers.vector.normal.trafficincidents;
        }

        var mapOptions = {};

        if (this.args.zoom) {
          mapOptions.zoom = this.args.zoom;
        }

        if (this.args.lat && this.args.lng) {
          mapOptions.center = {
            lat: this.args.lat,
            lng: this.args.lng
          };
        }

        this.map = new _mapsApiForJavascript.default.Map(document.getElementById('map'), this.layers.vector.normal.map, mapOptions); //Make the map interactive

        new _mapsApiForJavascript.default.mapevents.Behavior(new _mapsApiForJavascript.default.mapevents.MapEvents(this.map));
        window.addEventListener('resize', () => this.map.getViewPort().resize()); //Prevent scroll events on the map from affecting the page

        document.getElementById('map').addEventListener('wheel', function (event) {
          if (!event.target.closest('#toggle-markers-overlay')) {
            event.preventDefault();
          }
        }, {
          passive: false
        }); //Start with default map controls

        var userLocale = (_this$sessionData$get = this.sessionData.getUser().get('locale')) !== null && _this$sessionData$get !== void 0 ? _this$sessionData$get : 'en-US';
        this.ui = _mapsApiForJavascript.default.ui.UI.createDefault(this.map, this.layers, userLocale);
        this.ui.getControl('zoom').setAlignment('top-right');
        this.ui.getControl('mapsettings').setAlignment('top-right');
        this.ui.getControl('scalebar').setAlignment('bottom-middle'); //Just make US and CA users imperial for now

        if (userLocale.includes('US') || userLocale.includes('CA')) {
          this.ui.setUnitSystem('imperial');
        } //Hide uneeded controls


        if (!this.args.satellite && !this.args.traffic) {
          this.ui.getControl('mapsettings').setVisibility(false);
        }
      } catch (err) {
        throw err;
      }
    } //returns a point that is offset if a marker of the passed lat/lng exists in the list of markers


    getOffsetPoint(lat, lng, markers) {
      var newLat = Number(lat);
      var newLng = Number(lng);

      while (markers.some(marker => marker.getGeometry().lat === newLat && marker.getGeometry().lng === newLng)) {
        newLat += 0.00001;
        newLng += 0.00001;
      }

      return new _mapsApiForJavascript.default.geo.Point(newLat, newLng);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "map", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ui", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "layers", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "markers", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "initMap", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "initMap"), _class.prototype)), _class));
  _exports.default = STMap;
});